import * as React from "react";
import { useState } from "react";
import { Link } from "gatsby";
import * as styles from "./Menu.module.scss";

import logo from "@images/magyk_logo_new_font.svg";

const pages = [
  { title: "Services", slug: "/services" },
  // { title: "Portfolio", slug: "/portfolio" },
  { title: "About Us", slug: "/aboutUs" },
  { title: "Articles", slug: "/articles" },
  { title: "Events", slug: "/events" },
  { title: "Staff", slug: "/staff" },
  { title: "Contact Us", slug: "/contactUs" },
];

const Menu = ({ siteTitle, path }) => {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <header className={styles.header}>
      <div className={`${styles.inner_header} container`}>
        <div className={styles.logo_menu}>
          <Link to="/" className={styles.logo_container} title="Magyk Software">
            {/* <div className={styles.logo}></div> */}
            <img
              src={logo}
              className={styles.logo_hover}
              alt="Magyk Software Landing Page"
            />
          </Link>

          <div
            className={`${styles.burger_icon} ${menuOpen && styles.menu_open}`}
            onClick={toggleMenu}
          >
            <div className={styles.burger_span}></div>
            <div className={styles.burger_span}></div>
            <div className={styles.burger_span}></div>
          </div>
        </div>
        <nav>
          <ul
            className={`${styles.nav_list} ${menuOpen && styles.open_nav_list}`}
          >
            {pages.map(page => (
              <li
                key={page.slug}
                className={
                  page.slug === "/services" ? styles.dropdown_container : ""
                }
              >
                <Link to={page.slug}>
                  <span
                    className={`${styles.nav_link} ${
                      path === page.slug ? styles.active_link : ""
                    }`}
                  >
                    {page.title}
                  </span>
                </Link>
                {page.slug === "/services" && (
                  <div className={styles.dropdown}>
                    <Link to={"/services/engagement"}>
                      <span
                        className={
                          path === "/services/engagement"
                            ? styles.active_link
                            : ""
                        }
                      >
                        Engagement
                      </span>
                    </Link>
                    <Link to={"/services/websites"}>
                      <span
                        className={
                          path === "/services/websites"
                            ? styles.active_link
                            : ""
                        }
                      >
                        Websites
                      </span>
                    </Link>
                    <Link to={"/services/marketing"}>
                      <span
                        className={
                          path === "/services/marketing"
                            ? styles.active_link
                            : ""
                        }
                      >
                        Marketing
                      </span>
                    </Link>
                    <Link to={"/services/apps"}>
                      <span
                        className={
                          path === "/services/apps"
                            ? styles.active_link
                            : ""
                        }
                      >
                        Apps
                      </span>
                    </Link>
                      {/*staffing page has a /staffing path instead of /services/staffing */}
                    <Link to={"/staffing"}>
                      <span
                        className={
                          path === "/staffing"
                            ? styles.active_link
                            : ""
                        }
                      >
                        Staffing
                      </span>
                    </Link>

                  </div>
                )}
              </li>
            ))}
          </ul>
        </nav>
      </div>
    </header>
  );
};

export default Menu;
