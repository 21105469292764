import * as React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";
import * as styles from "./footer.module.scss";

import youtube from "@images/social/youtube.svg";
import linkedin from "@images/social/linkedin.svg";
import instagram from "@images/social/instagram.svg";
import twitter from "@images/social/twitter.svg";

import logo from "@images/footer_logo.svg";

const siteLinksColumn1 = [
  {
    title: "Services",
    slug: "/services"
  },
  {
    title: "Articles",
    slug: "/articles"
  },
];

const siteLinksColumn2 = [
  {
    title: "About Us",
    slug: "/aboutUs"
  },
  {
    title: "Staff",
    slug: "/staff"
  },
  {
    title: "Careers",
    slug: "/careers"
  }
];

const NavItem = ({ item, path }) => {
  const { title, slug } = item;

  return (
    <li>
      <Link className={path == slug ? styles.active : ""} to={slug}>
        {title}
      </Link>
    </li>
  );
};

const Footer = ({ siteTitle, path }) => (
  <footer className={`${styles.footer}`}>
    <nav className={`${styles.nav_list} container`}>
      <div>
        <h5 className={styles.nav_item_title}>Our Work</h5>
        <ul>
          {siteLinksColumn1.map(item => (
            <NavItem
              index={item.slug}
              item={item}
              path={path}
              key={item.slug}
            />
          ))}
          <li>
            <a  href="mailto:support@magyk.cloud">
              support@magyk.cloud
            </a>
          </li>
        </ul>
      </div>

      <div>
        <h5 className={styles.nav_item_title}>Company</h5>
        <ul>
          {siteLinksColumn2.map(item => (
            <NavItem
              index={item.slug}
              item={item}
              path={path}
              key={item.slug}
            />
          ))}
        </ul>
      </div>

      <div className={styles.contact}>
        <h5 className={`${styles.orange_title}`}>Let's Talk</h5>
        <h5>
          <Link
            to="/contactUs"
            className={path == "/contactUs" ? styles.active : ""}
          >
            Contact us
          </Link>
        </h5>
        <h5>
          <Link
            to="/events"
            className={path == "/events" ? styles.active : ""}
          >
            Events
          </Link>
        </h5>
        <h5>
          <Link
            to="/staffing"
            className={path == "/staffing" ? styles.active : ""}
          >
            Staffing
          </Link>
        </h5>
        <h5>
          <a className={styles.mail_phone} href="mailto:sales@magyk.cloud">
            sales@magyk.cloud
          </a>
        </h5>
        <h5>
          <a className={styles.mail_phone} href="tel:+13072631888">
            +1 307 263 1888
          </a>
        </h5>
      </div>

      <Link to="/" clasName={styles.logo_link}>
        {/* <div className={styles.logo}></div> */}
        <img
          src={logo}
          className={styles.logo}
          alt="Magyk Software Landing Page"
        />
      </Link>
    </nav>

    <div className={styles.bottom_info}>
      {/* <div style={{ display: "flex" }}>
        <h4 className="text_light">Follow us</h4>
        <img src={twitter} className={styles.icon} alt="go to twitter" />
        <img src={linkedin} className={styles.icon} alt="go to linkedIn" />
        <img src={instagram} className={styles.icon} alt="go to instagram" />
        <img src={youtube} className={styles.icon} alt="go to youtube" />
      </div> */}
      <h5 className="text_light">&#169;Copyright 2021 by Magyk Software</h5>
    </div>
  </footer>
);

Footer.propTypes = {
  siteTitle: PropTypes.string
};

Footer.defaultProps = {
  siteTitle: ``
};

export default Footer;
