// extracted by mini-css-extract-plugin
export const header = "Menu-module--header--2UCdX";
export const logo_menu = "Menu-module--logo_menu--14dwu";
export const burger_icon = "Menu-module--burger_icon--2dr2_";
export const burger_span = "Menu-module--burger_span--13tsF";
export const menu_open = "Menu-module--menu_open--18Nll";
export const nav_list = "Menu-module--nav_list--3Sjox";
export const nav_link = "Menu-module--nav_link--1L_TU";
export const dropdown_container = "Menu-module--dropdown_container--3bLHK";
export const dropdown = "Menu-module--dropdown--1DGlI";
export const active_link = "Menu-module--active_link--15fm4";
export const open_nav_list = "Menu-module--open_nav_list--y9evv";
export const logo_container = "Menu-module--logo_container--1ESTg";
export const logo = "Menu-module--logo--2hG70";
export const logo_hover = "Menu-module--logo_hover--3DCG5";
export const inner_header = "Menu-module--inner_header--krU7s";
export const bouncy = "Menu-module--bouncy--3bWx0";
export const pop = "Menu-module--pop--3EysT";