import * as React from "react"
import PropTypes from "prop-types"
import Footer from "./Footer"
import Menu from "./Menu"
import "./layout.css"
import "@fontsource/oldenburg" //
import { Helmet } from "react-helmet"

const Layout = ({ path, children }) => {

  return (
    <>
      <Menu path={path} />
      <div style={{paddingTop: "15px"}}>
        <main>{children}</main>
      </div>
      <Helmet>
        <script type="text/javascript" src="/chatbot.js"/>
      </Helmet>
      <Footer path={path} />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
