// extracted by mini-css-extract-plugin
export const footer = "footer-module--footer--3VpLX";
export const orange_title = "footer-module--orange_title--1INxM";
export const nav_list = "footer-module--nav_list--50xNi";
export const nav_item_title = "footer-module--nav_item_title--3wcZx";
export const mail_phone = "footer-module--mail_phone--2H7oU";
export const bottom_info = "footer-module--bottom_info--1Os19";
export const icon = "footer-module--icon--RjBgY";
export const bouncy = "footer-module--bouncy--V7D_R";
export const logo = "footer-module--logo--1khNe";
export const contact = "footer-module--contact--34eRb";
export const active = "footer-module--active--1X-p6";
export const pop = "footer-module--pop--Geal2";